/* You can add global styles to this file, and also import other style files */

html, body {
     height: 100%; 
     background-color: white !important;

     .mdc-data-table__cell > img {
	 max-width: 200px;
     }
}

body { 
    margin: 0; 
}


* {
    font-family: 'Righteous', cursive;
}
